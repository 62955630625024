import React, { ReactElement } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  cn,
  Dialog,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@mindstonehq/ui";
import { Link, useLocation, useNavigate } from "react-router";
import {
  useIsAuthenticated,
  useUser,
  useCurrentSpace,
} from "~/hooks/context-hook";
import { CogIcon, UserCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import { HelpCircleIcon, LogOutIcon, SearchIcon } from "lucide-react";
import Notifications from "~/components/notifications/notifications";
import { useToggleCommandPalette } from "~/components/command-palette/command-palette-context";
import { PointsAndLevelDisplay } from "~/components/points/points-display";
import metrics from "~/utils/metrics";

export const PageContainer = ({
  children,
  title,
  noMargin,
  noGap,
  className,
  hideTopBar,
}: React.PropsWithChildren<{
  title?: string;
  noGap?: boolean;
  noMargin?: boolean;
  className?: string;
  hideTopBar?: boolean;
}>) => {
  const navigate = useNavigate();
  const user = useUser();
  const location = useLocation();
  const authenticated = useIsAuthenticated();
  const space = useCurrentSpace();
  const breadcrumbs = React.Children.toArray(children)
    .filter(
      (child): child is React.ReactElement<any> =>
        React.isValidElement(child) && child.type === PageContainer.Breadcrumb,
    )
    .reduce((prev, next, i) => {
      return [...prev, next, <BreadcrumbSeparator key={"separator" + i} />];
    }, [] as ReactElement[])
    .slice(0, -1);

  const mainContent = React.Children.toArray(children).filter(
    (child) =>
      !React.isValidElement(child) ||
      (child.type != PageContainer.ButtonAction &&
        child.type != PageContainer.Action &&
        child.type != PageContainer.DialogAction &&
        child.type != PageContainer.Breadcrumb),
  );
  const [container, setContainer] = React.useState<any>(null);
  const toggleCommandPalette = useToggleCommandPalette();
  return (
    <div className={"flex flex-col grow h-full overflow-auto"}>
      {!hideTopBar && (
        <div className="shrink-0 md:sticky top-0 border-b border-border flex bg-card w-full px-4 md:px-6 lg:px-8 xl:px-10">
          <div className="w-full h-16  mx-auto py-4 flex items-center sm:justify-between">
            {!!breadcrumbs.length && !title && (
              <Breadcrumb>
                <BreadcrumbList>{breadcrumbs}</BreadcrumbList>
              </Breadcrumb>
            )}

            {!breadcrumbs.length && title && (
              <h4 className="min-w-0 flex-1 text-xl font-semibold leading-7 line-clamp-1">
                {title}
              </h4>
            )}
            {!!breadcrumbs.length && title && (
              <span className="text-destructive">
                Designers said it's not allowed...
              </span>
            )}

            <div className="hidden h-16 md:flex items-center w-full sm:w-fit justify-end gap-8">
              <Button
                variant="outline"
                size="sm"
                onClick={toggleCommandPalette}
              >
                <SearchIcon className="h-4 w-4" />
              </Button>
              <div className="border-l border-border h-9 w-1" />
              {authenticated && (
                <div className="flex flex-row gap-3 items-center">
                  {(space?.programs?.enrolled?.total ?? 0) > 0 && (
                    <PointsAndLevelDisplay variant="desktop" />
                  )}
                  <Notifications container={container} />
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild className="cursor-pointer">
                      <Button
                        variant="link"
                        className="h-8 w-8 cursor-pointer p-0"
                      >
                        <span className="sr-only">Your profile</span>
                        <img
                          className="h-8 w-8 bg-gray-50 rounded-full cursor-pointer"
                          src={user?.profilePictureURL || ""}
                          alt=""
                        />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                      <DropdownMenuLabel>My Account</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuGroup>
                        <DropdownMenuItem
                          onClick={() => navigate(`/profile/${user?.username}`)}
                        >
                          <UserCircleIcon className="mr-2 h-4 w-4" />
                          <span>Profile</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => navigate("/settings")}>
                          <CogIcon className="mr-2 h-4 w-4" />
                          <span>Settings</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem className="ms-intercom-launcher">
                          <HelpCircleIcon className="mr-2 h-4 w-4" />
                          <span>Help</span>
                        </DropdownMenuItem>
                      </DropdownMenuGroup>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        onClick={() => {
                          metrics.track("logout");
                          navigate("/logout");
                        }}
                      >
                        <LogOutIcon className="mr-2 h-4 w-4" />
                        <span>Logout</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              )}
              {!authenticated && (
                <Button variant={"secondary"} size="sm" asChild>
                  <Link to={`/login?redirectUrl=${location.pathname}`}>
                    {" "}
                    Login
                  </Link>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className=" grow flex flex-col md:overflow-hidden"
        ref={setContainer}
      >
        {/*<div className="min-h-full">*/}
        {/* Don't ask why, but this fix scrolling.*/}
        <div
          className={cn(
            " flex-1 grow  md:overflow-auto min-h-0 h-full grid grid-cols-12",
            !!noGap ? "" : "gap-4 sm:gap-5 lg:gap-6 2xl:gap-8",
            !!noMargin ? "" : "p-4 sm:p-5 md:p-6 lg:p-8 xl:p-10 2xl:p-12 ",
            className,
          )}
        >
          {mainContent}
        </div>
        {/*</div>*/}
      </div>
    </div>
  );
};

PageContainer.Action = React.forwardRef<
  HTMLElement,
  React.ComponentProps<typeof Button>
>(({ children }, ref) => {
  return children;
});
PageContainer.ButtonAction = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ children, ...props }, ref) => {
  return <Button {...props}>{children}</Button>;
});

PageContainer.DialogAction = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Dialog>
>(({ children, ...props }, ref) => {
  return <Dialog {...props}>{children}</Dialog>;
});

PageContainer.Breadcrumb = ({
  title,
  href,
}: {
  title: string;
  href?: string;
}) => {
  if (href) {
    return (
      <BreadcrumbItem>
        <BreadcrumbLink asChild>
          <Link
            to={href}
            className="text-placeholder-foreground hover:text-foreground"
          >
            {title}
          </Link>
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  }
  return (
    <BreadcrumbItem>
      <BreadcrumbLink className="text-foreground font-semibold">
        {title}
      </BreadcrumbLink>
    </BreadcrumbItem>
  );
};
export default PageContainer;
